import { IAddress } from "@saleor/sdk/lib/api/Checkout/types"

export const shippingAdressInitialState: { selected: IAddress } = {
  selected: null as unknown as IAddress,
}

// Todo clean du  reducer inutile
export function shippingAdressReducer(
  state = shippingAdressInitialState,
  action: {
    type: string
    selectedAdress: IAddress
  }
) {
  switch (action.type) {
    case "select":
      return {
        ...state,
        selected: action.selectedAdress,
      }
    case "clear":
      return { ...state, selected: null }
    default:
      return state
  }
}

export function initPointLivraisonReducer(initValue: IAddress) {
  return { selected: initValue }
}
//******************************Action ********************************************** */

export function changeshippingAdress(newAdress: IAddress) {
  return {
    type: "select",
    selectedAdress: newAdress,
  }
}

export function unselectshippingAdress() {
  return {
    type: "clear",
  }
}
