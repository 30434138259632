import { MenuSelectReducerState } from "./menuSelect.types"
import {
  MenuSelectActionTypes,
  SET_MENUSELECTED,
  CLEAR_MENUSELECTED,
} from "./menuSelect.actionTypes"

const defaultState: MenuSelectReducerState = {
  menuActive: { id: "" },
}

export default function menuSelectReducer(
  state = defaultState,
  action: MenuSelectActionTypes
): MenuSelectReducerState {
  switch (action.type) {
    case SET_MENUSELECTED:
      return {
        ...state,
        menuActive: action.menu,
      }
    case CLEAR_MENUSELECTED:
      return defaultState
    default:
      return state
  }
}
