import { ProductQuantity } from "./cart.types"

export function calculateTotalPricing(items: ProductQuantity[]) {
  var priceNodes = ["gross", "tax", "net"]
  var totalDiscount = {
    // used here as final price, on sale or not
    currency: "EUR",
    gross: { amount: 0 },
    tax: { amount: 0 },
    net: { amount: 0 },
  }
  var totalOriginal = {
    // used here as original price, on sale or not
    currency: "EUR",
    gross: { amount: 0 },
    tax: { amount: 0 },
    net: { amount: 0 },
  }
  var onSale = false

  items.map(item => {
    var pricing = item.product.variant.pricing
    if (pricing.onSale) {
      onSale = true
      totalDiscount.currency = pricing.discount.currency
      priceNodes.map(key => {
        totalDiscount[key].amount =
          (totalDiscount[key].amount * 100 +
            item.quantity *
              (pricing.price[key].amount * 100 -
                pricing.discount[key].amount * 100)) /
          100
      })
    } else {
      totalDiscount.currency = pricing.price.currency
      priceNodes.map(key => {
        totalDiscount[key].amount =
          (totalDiscount[key].amount * 100 +
            item.quantity * 100 * pricing.price[key].amount) /
          100
      })
    }
    totalOriginal.currency = pricing.price.currency
    priceNodes.map(key => {
      totalOriginal[key].amount =
        (totalOriginal[key].amount * 100 +
          item.quantity * 100 * pricing.price[key].amount) /
        100
    })
  })
  return {
    onSale: onSale,
    discount: totalDiscount,
    price: totalOriginal,
  }
}

export function calcularteNumberOfItems(items: ProductQuantity[]) {
  let count = 0
  items.map(item => {
    count += item.quantity
  })
  return count
}


