import {
  AnchorListItemActionTypes,
  CLEAR_ANCHOR_LISTITEM,
  CLEAR_ANCHOR_LISTITEM_ALL,
  SET_ANCHOR_LISTITEM,
  UPDATE_ANCHOR_LISTITEM,
} from "./anchorListItem.actionTypes"
import { AnchorListItemReducerState } from "./anchorListItem.types"

const defaultState: AnchorListItemReducerState = {
  itemAnchor: "",
  after: "",
  numberOfItem: 0,
  products: [],
  url: ""
}

export default function anchorListItemReducer(
  state = defaultState,
  action: AnchorListItemActionTypes
): AnchorListItemReducerState {
  switch (action.type) {
    case SET_ANCHOR_LISTITEM:
      return {
        ...state,
        itemAnchor: action.itemAnchor,
      }
    case UPDATE_ANCHOR_LISTITEM:
      return {
        ...state,
        after: action.after,
        numberOfItem: action.numberOfItem,
        products: [...action.products],
        url: action.url
      }
    case CLEAR_ANCHOR_LISTITEM:
      return {
        ...state,
        itemAnchor: "",
      }
    case CLEAR_ANCHOR_LISTITEM_ALL:
      return defaultState
    default:
      return state
  }
}
