import { AuthState, Address } from "./auth.types"
import { AuthActionTypes, SET_AUTH, LOGOUT, SET_USER } from "./auth.actionTypes"

const defaultAddress: Address = {
  firstName: "",
  lastName: "",
  companyName: "",
  streetAddress1: "",
  streetAddress2: "",
  city: "",
  cityArea: "",
  postalCode: "",
  country: {
    code: "",
    country: "",
  },
  countryArea: "",
  phone: "",
}

const defaultAuthState: AuthState = {
  authData: {
    token: "",
    csrfToken: "",
  },
  userData: {
    email: "",
    firstName: "",
    lastName: "",
    defaultBillingAddress: defaultAddress,
    defaultShippingAddress: defaultAddress,
  },
}

export default function authReducer(
  state = defaultAuthState,
  action: AuthActionTypes
): AuthState {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        authData: action.authData,
      }
    case SET_USER:
      return {
        ...state,
        userData: action.userData,
      }
    case LOGOUT:
      return defaultAuthState
    default:
      return state
  }
}
