import React from "react"
import { Provider as ReduxProvider, useSelector } from "react-redux"
import { setContext } from "@apollo/client/link/context"
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client"
import { ApolloProvider } from "@apollo/client/react"
// TODO : Js / damien (il y a deux fetch dans l'app)
import fetch from "isomorphic-fetch"
import { PersistGate } from "redux-persist/integration/react"
import { store, persistor } from "./../store/configure"

const httpLink = new HttpLink({
  uri: `${process.env.GATSBY_SALEOR_URL_GRAPHQL}`,
  fetch,
})

const authLink = setContext((_, { headers }) => {
  // TODO:DH you can use this syntax to access reducer if hook is not possible, like here
  const { token } = store.getState().authReducer.authData
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    },
  }
})

// TODO:DH for optimization, try the option ssrMode:true as describe here: https://www.apollographql.com/docs/react/performance/server-side-rendering/
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
})

export default ({ element }) => {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={client}>{element}</ApolloProvider>
      </PersistGate>
    </ReduxProvider>
  )
}
