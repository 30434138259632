import { Auth, User } from "./auth.types"

export const SET_AUTH = "SET_AUTH"
export const LOGOUT = "LOGOUT"
export const SET_USER = "SET_USER"

export interface SetAuth {
  type: typeof SET_AUTH
  authData: Auth
}

export interface SetUser {
  type: typeof SET_USER
  userData: User
}

export interface Logout {
  type: typeof LOGOUT
}

export type AuthActionTypes = SetAuth | Logout | SetUser
