export const SET_MENUSELECTED = "SET_MENUSELECTED"
export const CLEAR_MENUSELECTED = "CLEAR_MENUSELECTED"

import { MenuSelect } from "./menuSelect.types"

export interface SetMenuSelected {
  type: typeof SET_MENUSELECTED
  menu: MenuSelect
}

export interface ClearMenuSelectList {
  type: typeof CLEAR_MENUSELECTED
}

export type MenuSelectActionTypes = SetMenuSelected | ClearMenuSelectList
