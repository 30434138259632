/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import wrapWithPageProviders from "./src/providers/page-providers"
import wrapWithRootProviders from "./src/providers/root-providers"
import "@fontsource/poppins"; // Defaults to weight 400.
import "@fontsource/cormorant-garamond"

export const wrapPageElement = wrapWithPageProviders
export const wrapRootElement = wrapWithRootProviders
