import { CookieMsgReducerState } from "./cookieMsg.types"
import { CookieMsgActionTypes, SET_COOKIEMSG } from "./cookieMsg.actionTypes"

const defaultState: CookieMsgReducerState = {
  alreadyShow: false,
}

export default function cookieMsgReducer(
  state = defaultState,
  action: CookieMsgActionTypes
): CookieMsgReducerState {
  switch (action.type) {
    case SET_COOKIEMSG:
      return {
        ...state,
        alreadyShow: action.alreadyShow,
      }
    default:
      return state
  }
}
