import { SearchKeyReducerState } from "./searchKey.types"
import {
  SearchKeyActionTypes,
  SET_SEARCHKEY_LIST,
  CLEAR_SEARCHKEY_LIST,
} from "./searchKey.actionTypes"

const defaultState: SearchKeyReducerState = {
  keyword: "",
}

export default function searchKeyReducer(
  state = defaultState,
  action: SearchKeyActionTypes
): SearchKeyReducerState {
  switch (action.type) {
    case SET_SEARCHKEY_LIST:
      return {
        ...state,
        keyword: action.keyword,
      }
    case CLEAR_SEARCHKEY_LIST:
      return defaultState
    default:
      return state
  }
}
