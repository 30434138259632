export const SET_SEARCHKEY_LIST = "SET_SEARCHKEY_LIST"
export const CLEAR_SEARCHKEY_LIST = "CLEAR_SEARCHKEY_LIST"

import { SearchKeyReducerState } from "./searchKey.types"

export interface SetSearchKeyList {
  type: typeof SET_SEARCHKEY_LIST
  keyword: SearchKeyReducerState
}

export interface ClearSearchKeyList {
  type: typeof CLEAR_SEARCHKEY_LIST
}

export type SearchKeyActionTypes = SetSearchKeyList | ClearSearchKeyList
