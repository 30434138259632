export interface DataSearchEngine {
  index: any
  store: any
}

export const dataSearchEngineInitialState = {
  dataSearchEngine: { index: null, store: null },
}

// Todo clean du  reducer inutile
export function dataSearchEngineReducer(
  state = dataSearchEngineInitialState,
  action: {
    type: any
    dataSearchEngine: DataSearchEngine
  }
) {
  switch (action.type) {
    case "set":
      return {
        ...state,
        dataSearchEngine: action.dataSearchEngine,
      }
    case "reset":
      return { ...state, dataSearchEngine: null }
    default:
      return state
  }
}

export function initDataSearchEngine(initValue: DataSearchEngine) {
  return {
    type: "set",
    dataSearchEngine: initValue,
  }
}

export function changeDataSearchEngine() {
  return {
    type: "reset",
  }
}
