export const SET_ANCHOR_LISTITEM = "SET ANCHOR LISTITEM"
export const CLEAR_ANCHOR_LISTITEM = "CLEAR ANCHOR LISTITEM"
export const UPDATE_ANCHOR_LISTITEM = "UPDATE ANCHOR LISTITEM"
export const CLEAR_ANCHOR_LISTITEM_ALL = "CLEAR ANCHOR LISTITEM ALL"
import { AnchorListItemReducerState } from "./anchorListItem.types"

export interface setAnchorListItem {
  type: typeof SET_ANCHOR_LISTITEM
  itemAnchor: string
}

interface updateAnchorListItem {
  type: typeof UPDATE_ANCHOR_LISTITEM
  after: string
  numberOfItem: number
  products: any
  url: string
}

export interface ClearAnchorListItem {
  type: typeof CLEAR_ANCHOR_LISTITEM
}
export interface ClearAnchorListItemAll {
  type: typeof CLEAR_ANCHOR_LISTITEM_ALL
}


export type AnchorListItemActionTypes = setAnchorListItem | ClearAnchorListItem | updateAnchorListItem | ClearAnchorListItemAll
