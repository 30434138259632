import { DefaultTheme } from "styled-components"

export const defaultTheme: DefaultTheme = {
  palette: {
    common: { black: "#000", white: "#fff" },
    background: { paper: "#fff", default: "#fff", gray: "#d9d9d9 " },
    primary: {
      light: "#f1f1f1",
      main: "#2B3944",
      contrastText: "#7d7d7d",
    },
    secondary: {
      main: "#C6AE8C",
      contrastText: "#333",
    },
    error: {
      main: "#b04059",
      contrastText: "#fff",
    },
    warning: {
      main: "#ff9800",
      contrastText: "#333",
    },
    info: {
      main: "#35b736",
      contrastText: "#fff",
    },
    button: {
      main: "#2B3944",
      homepage: "#444444",
      contrastText: "#fff",
    },
    text: {
      primary: "#2B3944",
      disabled: "#7d7d7d",
      hint: "#7d7d7d",
    },
  },
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
  ],
  shape: {
    borderRadius: "4px",
  },
  transitions: {
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  typography: {
    htmlFontSize: "16px",
    fontFamily: '"Poppins", "Arial", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    color: "#2B3944",

    body: {
      fontFamily: '"Poppins", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: "1rem",
      color: "#2B3944",
    },
    h1: {
      fontWeight: 700,
      fontSize: "2rem",
      textAlign: "center",
      marginBottom: "0.5em",
      font: "normal normal 600 50px Cormorant Garamond",
      letterSpacing: "-1.5px",
      opacity: 1,
      color: "#2B3944",
    },
    h2: {
      font: "normal semiBold 600 30px Cormorant Garamond",
      fontWeight: 600,
      // fontSize: "1.5rem",
      textAlign: "left",
      color: "#2B3944",
    },
    h3: {
      font: "normal normal 600 18px Cormorant Garamond",
      fontWeight: 700,
      fontSize: "1.5rem",
      color: "#2B3944",
    },
    p: {
      textAlign: "center",
      font: "normal normal 300 16px Poppins",
      letterSpacing: "-0.45px",
      color: "#2B3944",
      opacity: 1,
    },
    subtitle: {
      fontFamily: '"Poppins", "Arial", sans-serif',
      fontWeight: 700,
      fontSize: "1.125rem",
      color: "#2B3944",
    },
    button: {
      fontFamily: '"Poppins", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "15px",
      lineHeight: 1.75,
      color: "#2B3944",
    },
    info: {
      fontFamily: '"Poppins", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "0.75rem",
    },
  },
  zIndex: {
    menu: 100,
    modal: 200,
    tooltip: 300,
  },

}
