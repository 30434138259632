export const ADD_TO_CART = "ADD_TO_CART"
export const UPDATE_QUANTITY = "UPDATE_QUANTITY"
export const REMOVE_FROM_CART = "REMOVE_FROM_CART"
export const CLEAR_CART = "CLEAR_CART"

import { Product } from "./cart.types"

export interface AddToCart {
  type: typeof ADD_TO_CART
  product: Product
  quantity: number
}

export interface UpdateQuantity {
  type: typeof UPDATE_QUANTITY
  variantId: string
  quantity: number
}

export interface RemoveFromCart {
  type: typeof REMOVE_FROM_CART
  variantId: string
}

export interface ClearCart {
  type: typeof CLEAR_CART
}

export type CartActionTypes =
  | AddToCart
  | UpdateQuantity
  | RemoveFromCart
  | ClearCart
