import React from "react"
import { ThemeProvider } from "styled-components"
import { theme } from "./../assets/themes"
import { SaleorProvider } from "@saleor/sdk"

const config = {
  apiUrl: process.env.GATSBY_SALEOR_URL_GRAPHQL,
  channel: "default-channel",
}
const apolloConfig = {
  /* 
    Optional custom Apollo client config.
    Here you may append custom Apollo cache, links or the whole client. 
    You may also use import { createSaleorCache, createSaleorClient, createSaleorLinks } from "@saleor/sdk" to create semi-custom implementation of Apollo.
  */
}
export default ({ element, props }) => {
  return (
    <SaleorProvider config={config} apolloConfig={apolloConfig}>
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </SaleorProvider>
  )
}
