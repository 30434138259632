export const SET_HEADERSTATUS = "SET_HEADERSTATUS"

import { HeaderStatusReducerType } from "./headerStatus.types"

export interface SetHeaderStatusType {
  type: typeof SET_HEADERSTATUS
  data: HeaderStatusReducerType
}

export type HeaderStatusActionTypes = SetHeaderStatusType
