import { createStore as reduxCreateStore } from "redux"
import { persistStore, persistReducer, PersistConfig } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly"

import rootReducer from "./rootReducer"

const persistConfig = {
  key: "root",
  storage,
  // Reducers you want to persist, all if commented
  // whitelist: [],
  blacklist: ["dataSearchEngineReducer"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
var createStore

if (typeof window != "undefined") {
  createStore = () => reduxCreateStore(persistedReducer, composeWithDevTools())
} else {
  createStore = () => reduxCreateStore(persistedReducer)
}

export type AppState = ReturnType<typeof rootReducer>
export const store = createStore()
export const persistor = persistStore(store)
