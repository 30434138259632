import { cartReducerState } from "./cart.types"
import { calcularteNumberOfItems, calculateTotalPricing } from "./cart.service"
import {
  CartActionTypes,
  ADD_TO_CART,
  UPDATE_QUANTITY,
  REMOVE_FROM_CART,
  CLEAR_CART,
} from "./cart.actionTypes"

const defaultPrice = {
  currency: "EUR",
  gross: { amount: 0 },
  tax: { amount: 0 },
  net: { amount: 0 },
}

const defaultState: cartReducerState = {
  list: [],
  numberOfItems: 0,
  totalPricing: {
    onSale: false,
    discount: defaultPrice,
    price: defaultPrice,
  },
}

export default function cartReducer(
  state = defaultState,
  action: CartActionTypes
): cartReducerState {
  switch (action.type) {
    case ADD_TO_CART:
      var cart = state.list
      var alreadyInCart = false
      for (var i = 0; i < cart.length; i++) {
        if (cart[i].product.variant.id == action.product.variant.id) {
          alreadyInCart = true
          cart[i].quantity += action.quantity
        }
      }
      if (!alreadyInCart) {
        cart.push({ quantity: action.quantity, product: action.product })
      }
      return {
        ...state,
        list: cart,
        numberOfItems: calcularteNumberOfItems(cart),
        totalPricing: calculateTotalPricing(cart),
      }
    case UPDATE_QUANTITY:
      var cart = state.list
      for (var i = 0; i < cart.length; i++) {
        if (cart[i].product.variant.id == action.variantId) {
          cart[i].quantity = action.quantity
        }
      }
      return {
        ...state,
        list: cart,
        numberOfItems: calcularteNumberOfItems(cart),
        totalPricing: calculateTotalPricing(cart),
      }
    case REMOVE_FROM_CART:
      var cart = state.list
      for (var i = 0; i < cart.length; i++) {
        if (cart[i].product.variant.id == action.variantId) {
          cart.splice(i, 1)
        }
      }
      return {
        ...state,
        list: cart,
        numberOfItems: calcularteNumberOfItems(cart),
        totalPricing: calculateTotalPricing(cart),
      }
    case CLEAR_CART:
      return defaultState
    default:
      return state
  }
}
