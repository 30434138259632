import { PointRelais } from "../../lib/map"

export const ActivePointLivraisonInitialState = {
  selected: "",
  pointLivraison: null,
}

// Todo clean du  reducer inutile
export function activePointLivraisonReducer(
  state = ActivePointLivraisonInitialState,
  action: {
    type: any
    selectedId: any
    selectedPointLivraison: PointRelais
  }
) {
  switch (action.type) {
    case "selected":
      return {
        ...state,
        selected: action.selectedId,
        pointLivraison: action.selectedPointLivraison,
      }
    case "unselected":
      return { ...state, selected: "", pointLivraison: "" }
    default:
      return state
  }
}

export function initPointLivraisonReducer(initValue: string) {
  return { selected: initValue }
}

export function changeActivePointLivraison(
  id: string,
  newPointLivraison: PointRelais
) {
  return {
    type: "selected",
    selectedId: id,
    selectedPointLivraison: newPointLivraison,
  }
}

export function unselectActivePointLivraison() {
  return {
    type: "unselected",
  }
}
