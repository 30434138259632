import { HeaderStatusReducerType } from "./headerStatus.types"
import {
  HeaderStatusActionTypes,
  SET_HEADERSTATUS,
} from "./headerStatus.actionTypes"

const defaultState: HeaderStatusReducerType = {
  searchActive: false,
  cartOpened: false,
}

export default function headerStatusReducer(
  state = defaultState,
  action: HeaderStatusActionTypes
): HeaderStatusReducerType {
  switch (action.type) {
    case SET_HEADERSTATUS:
      return {
        ...state,
        ...action.data,
      }
    default:
      return state
  }
}
