import { combineReducers } from "redux"

import headerStatusReducer from "../entities/headerStatus/headerStatus.reducer"
import cartReducer from "../entities/cart/cart.reducer"
import authReducer from "../entities/auth/auth.reducer"
import cookieMsgReducer from "../entities/cookieMsg/cookieMsg.reducer"
import { activePointLivraisonReducer } from "../entities/PointLivraison/PointLivraison.reducer"
import menuSelectReducer from "../entities/menuSelect/menuSelect.reducer"
import searchKeyReducer from "../entities/searchKey/searchKey.reducer"
import { shippingAdressReducer } from "../entities/shippingAddress/shippingAdress.reducer"
import { dataSearchEngineReducer } from "../entities/searchEngine/dataSearchEngine.reducer"
import anchorListItemReducer from "../entities/anchorListitem/anchorListItem.reducer"

// Plug new reducers here
export default combineReducers({
  headerStatusReducer,
  cartReducer,
  authReducer,
  cookieMsgReducer,
  activePointLivraisonReducer,
  menuSelectReducer,
  searchKeyReducer,
  shippingAdressReducer,
  dataSearchEngineReducer,
  anchorListItemReducer,
})
